<template>
    <div class="about">
        <div class="about-title">
            <div class="about-title-as">
                <h2>关于我们</h2>
                <h4>About as</h4>
            </div>
        </div>
        <div class="about-infor">
                <h5>公司简介</h5>
                <p>深圳市卡昂网络科技有限公司自2017年成立以来，一直致力于精品网络游戏的研发、运营、发行，从用户的最基本需求、最简单应用入手，注重产品的可持续发展和长久生命力，打造绿色健康的精品游戏。卡昂网络拥有年轻且实力强大的运营团队，并已在中国地区建立起庞大而稳固的用户流量渠道，专业提供有深度的游戏互动娱乐运营服务。</p>
        </div>
        <footer-info></footer-info>
    </div>
</template>

<script>
import FooterInfo from '../components/footer'
export default {
    components: {
        FooterInfo
    },
    data() {
        return {
            swiperOption: {
                pagination: {
                    el: '.swiper-pagination'
                }
            },
            swiperSlides: [
                '//cdn.51img3.com/game/2018/201807/20180720/4cb01b250b43a7299966271bf4fbdbe2.jpg',
                '//cdn.51img3.com/game/2018/201807/20180720/4cb01b250b43a7299966271bf4fbdbe2.jpg'
            ]
        }
    }
}
</script>
<style>
.about .about-title{
    width: 100%;
    height: 280px;
    background: url('../assets/images/bg.jpg') ;
}
.about-title-as{
    width: 1000px;
    margin:0 auto;
    color: #fff;
}
.about-title-as h2{
    font-size: 48px;
    padding-top:140px;
}
.about-title-as h4{
    font-size: 30px;
    padding-top:20px;
    color: #ffd44f;
}
.about-infor{
    margin:0 auto;
    width: 800px;
    height: 400px;
}
.about-infor h5{
   font-weight: bold;
   line-height: 200px;
}
.about-infor p{
   font-size: 14px;
   line-height: 30px;
}
</style>

